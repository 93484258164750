import React from 'react'
import { getSrc } from 'gatsby-plugin-image'
import WebsiteIcon from '../assets/images/website-icon.png'

const ORGANIZATION = {
  '@type': 'Organization',
  name: 'Swarmia',
  logo: {
    '@type': 'ImageObject',
    url: imageUrl(WebsiteIcon),
  },
  url: 'https://www.swarmia.com/',
  sameAs: [
    'https://twitter.com/swarmiahq/',
    'https://www.facebook.com/swarmiahq/',
    'https://www.linkedin.com/company/swarmia/',
    'https://www.youtube.com/@swarmia',
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Lapinlahdenkatu 16',
    postalCode: '00180',
    addressLocality: 'Helsinki',
    addressCountry: 'FI',
  },
  contactPoint: [
    {
      '@type': 'ContactPoint',
      email: 'hello@swarmia.com',
      contactType: 'Customer Support',
      contactOption: 'Swarmia Support',
    },
  ],
}

function LdJson(props: { schema: object }) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(props.schema),
      }}
    />
  )
}

export function BlogPostSchema(props) {
  const {
    url,
    image,
    wordCount,
    frontmatter: {
      dateIso,
      title,
      author,
      meta: { description },
    },
  } = props
  return (
    <LdJson
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        headline: title,
        image,
        wordCount,
        url,
        datePublished: dateIso,
        description,
        mainEntityOfPage: url,
        author: {
          '@type': 'Person',
          name: author.name,
          sameAs: author.linkedin,
          description: `${author.name} ${author.bio}`,
          image: imageUrl(getSrc(author.profilepicture)),
        },
        publisher: ORGANIZATION,
      }}
    />
  )
}

function imageUrl(path: string | undefined) {
  // Note: the full URL will only work on prod builds running at www.swarmia.com
  return path ? `https://www.swarmia.com${path}` : undefined
}
