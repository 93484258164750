import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import Block from '../components/Block'
import Blockquote from '../components/Blockquote'
import { BlogCta } from '../components/BlogCta'
import Layout from '../components/Layout'
import SecondaryCTA from '../components/SecondaryCTA'
import SidebarLayout from '../components/SidebarLayout'
import Social from '../components/Social'
import { TableOfContents } from '../components/TableOfContents'
import { TwitterEmbed } from '../components/TwitterEmbed'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import theme from '../theme'
import { Heading, parseToc } from '../tocUtils'
import YoutubeVideo from '../components/YoutubeVideo'
import { BlogPostSchema } from '../components/Schema'

export default function BlogTemplate({ data }) {
  const { mdx } = data
  const { frontmatter, body, wordCount } = mdx

  const url = 'https://www.swarmia.com' + frontmatter.slug
  const title = (frontmatter.meta.seoTitle || frontmatter.title) + ' | Swarmia'
  const description = frontmatter.meta.description

  const imageData = frontmatter?.meta?.image?.childImageSharp?.original
  const image = imageData?.src
    ? 'https://www.swarmia.com' + imageData.src
    : undefined
  const imageWidth = String(imageData?.width)
  const imageHeight = String(imageData?.height)

  const cover = getImage(frontmatter.meta.image)
  const avatar = getImage(frontmatter.author.profilepicture)

  const isLongArticle = frontmatter.articleType === 'long'
  const [headings, setHeadings] = useState<Heading[] | null>(null)
  const setContentRef = useCallback(
    node => {
      if (isLongArticle && node) {
        const headings = ['h2', 'h3', 'h4'].slice(0, frontmatter.tocDepth || 2)
        setHeadings(parseToc(Array.from(node.querySelectorAll(headings))))
      }
    },
    [frontmatter.tocDepth, isLongArticle],
  )
  const titleId = 'top'

  const titleSection = (
    <>
      <Title id={titleId}>{frontmatter.title}</Title>
      <Metadata>
        {frontmatter.author.name}, {frontmatter.author.jobTitle} ·{' '}
        <span style={{ whiteSpace: 'nowrap' }}>{frontmatter.date}</span>
      </Metadata>
      {cover && frontmatter.showCoverImage && (
        <div>
          <CoverImage>
            <GatsbyImage image={cover} alt={frontmatter.meta.imageAlt} />
          </CoverImage>
          <CoverImageAlt className="blog-cover-image-alt">
            {frontmatter.meta.imageAlt}
          </CoverImageAlt>
        </div>
      )}
    </>
  )
  const content = (
    <>
      <div ref={setContentRef} className="blog-post-content">
        <MDXProvider
          components={{
            // NOTE: If we start having many components here, implement lazy loading to keep things fast
            // See: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/#lazy-loading-components
            BlogCta,
            blockquote: props => (
              <Blockquote
                {...props}
                url={url}
                authorTwitterHandle={frontmatter.author.twitter}
              />
            ),
            YoutubeVideo: props => <YoutubeVideo borderRadius={0} {...props} />,
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
      {frontmatter.tweetId && (
        <EmbedWrapper>
          <TwitterEmbed tweetId={frontmatter.tweetId} />
        </EmbedWrapper>
      )}
    </>
  )
  const footer = (
    <>
      <Bio>
        {avatar && (
          <div className="bio-avatar">
            <GatsbyImage image={avatar} alt={frontmatter.author.name} />
          </div>
        )}
        <div className="bio-text">
          <span className="bio-name">{frontmatter.author.name}</span>{' '}
          {frontmatter.author.bio}
        </div>
      </Bio>
      <Social />
    </>
  )

  return (
    <Layout justifyCenter>
      <Helmet title={title}>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta name="author" content={frontmatter.author.name} />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={frontmatter.meta?.ogTitle || frontmatter.title}
        />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={'@' + frontmatter.author.twitter}
        />
        <meta
          name="twitter:title"
          content={frontmatter.meta?.ogTitle || frontmatter.title}
        />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <BlogPostSchema
        url={url}
        image={image}
        wordCount={wordCount.words}
        frontmatter={frontmatter}
      />
      {isLongArticle ? (
        <>
          <BlogPost isLong={isLongArticle}>
            {frontmatter.showCoverImage ? (
              <Block size="small">{titleSection}</Block>
            ) : (
              <Block size="large">
                <SidebarLayout main={titleSection} sidebar={<div />} />
              </Block>
            )}
            <Block size="large">
              <SidebarLayout
                sidebar={
                  <StickySidebar
                    headings={headings}
                    title={frontmatter.title}
                    titleId={titleId}
                  />
                }
                main={content}
              />
              <SidebarLayout main={footer} />
            </Block>
          </BlogPost>
          <Block size="large">
            <SidebarLayout main={<SecondaryCTA />} />
          </Block>
        </>
      ) : (
        <>
          <Block size="small">
            <BlogPost isLong={isLongArticle}>
              {titleSection}
              {content}
              {footer}
            </BlogPost>
          </Block>
          <Block size="large">
            <SecondaryCTA />
          </Block>
        </>
      )}
      <FeaturedBlogArticles
        articles={data.relatedPosts.edges.map(
          edge => edge.node.frontmatter.slug,
        )}
        heading="More content from Swarmia"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!, $relatedSlugs: [String!]!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      wordCount {
        words
      }
      frontmatter {
        date(formatString: "MMM D, YYYY")
        dateIso: date(formatString: "YYYY-MM-DD")
        slug
        title
        articleType
        tocDepth
        showCoverImage
        tweetId
        author {
          name
          jobTitle
          bio
          linkedin
          twitter
          profilepicture {
            childImageSharp {
              gatsbyImageData(width: 80, height: 80)
            }
          }
        }
        meta {
          description
          seoTitle
          ogTitle
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 90)
              original {
                src
                width
                height
              }
            }
          }
        }
      }
    }
    relatedPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { slug: { in: $relatedSlugs } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
          }
        }
      }
    }
  }
`

const CoverImage = styled.div`
  margin: 0 -180px 32px -180px;

  .gatsby-image-wrapper {
    border-radius: 32px;
  }

  @media (max-width: 1140px) {
    margin: 0 -140px 32px -140px;
  }

  // This is when the sidebar layout collapses
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    margin: 0;
    margin-bottom: 16px;
    .gatsby-image-wrapper,
    img {
      border-radius: 12px;
    }
  }
`

const imageAltStyles = css`
  line-height: 24px;
  color: ${theme.colors.black500};
  font-size: 16px;
`
const CoverImageAlt = styled.div`
  margin-bottom: 24px;
  ${imageAltStyles}

  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    margin-bottom: 64px;
  }
`

const BlogPost = styled.div<{ isLong?: boolean }>`
  margin: 2rem auto 0;

  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    margin: 8rem auto 0;
  }

  // Hide icons generated by gatsby-remark-autolink-headers
  a.anchor.before {
    display: none;
  }

  .blog-post-content {
    margin-bottom: 2rem;

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      margin-bottom: 6rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      width: 100%;
      scroll-margin-top: 100px;
    }


    .inline-list {
      padding-left: 2.5rem;

      li::before {
        content: '';
        width: 0.5rem;
        display: inline-block;
      }

      margin-bottom: 0;
    }

    cite {
      display: block;
      font-size: 0.75em;
      line-height: 1.8em;
      margin-top: -2rem;
      margin-bottom: 3rem;
    }

    .gatsby-highlight {
      margin: 2rem 0;
      font-size: 14px;

      pre {
        padding:1.5rem;
        border-radius:8px;
      }
    }

    & > p,
    & > div,
    & > a,
    & > .gatsby-resp-image-figure,
    & > .gatsby-resp-image-wrapper {
      display: block;
      margin-top: 32px;
    }

    .gatsby-resp-image-wrapper + .gatsby-resp-image-wrapper {
      margin-top: 0;
    }

    .gatsby-resp-image-figure {
      .gatsby-resp-image-wrapper {
        margin-bottom: 16px;
      }

      figcaption {
        ${imageAltStyles}
      }
    }

    .gatsby-resp-image-wrapper {
      &,
      img,
      .gatsby-resp-image-background-image {
        border-radius: 12px;
      }
    }

    .no-radius .gatsby-resp-image-wrapper {
      &,
      img,
      .gatsby-resp-image-background-image {
        border-radius: 0 !important;
      }
    }

    @media (min-width: 1349px) {
      --image-outset: ${props => (props.isLong ? '-40px' : '-180px')};
      --quote-outset: ${props => (props.isLong ? '-40px' : '-80px')};

      .gatsby-resp-image-wrapper {
        margin-left: var(--image-outset) !important;
        margin-right: var(--image-outset) !important;

        &,
        img,
        .gatsby-resp-image-background-image {
          border-radius: 32px;
        }
      }

      .max-width-100 .gatsby-resp-image-wrapper {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      blockquote,
      cite {
        margin-left: var(--quote-outset);
        margin-right: var(--quote-outset);
      }
    }
`

const EmbedWrapper = styled.div`
  margin-top: -74px;
  margin-bottom: 48px;

  // This is when the layout margins change
  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    margin-top: 0;
  }
`

const sidebarTopPx = 100

function StickySidebar({
  headings,
  title,
  titleId,
}: {
  headings: Heading[] | null
  title: string
  titleId: string
}) {
  const { ref, inView } = useInView({
    rootMargin: `-${sidebarTopPx}px 0px 0px 0px`,
    fallbackInView: true,
    initialInView: true,
  })

  return (
    <div style={{ height: '100%' }}>
      <div ref={ref} />
      <SidebarContent isSticky={!inView}>
        {headings ? (
          <TableOfContents
            title={title}
            titleId={titleId}
            headings={headings}
          />
        ) : null}
      </SidebarContent>
    </div>
  )
}

const SidebarContent = styled.div<{ isSticky: boolean }>`
  position: sticky;
  top: ${sidebarTopPx}px;
  max-height: calc(100vh - ${sidebarTopPx + 40}px);
  overflow-y: auto;
  z-index: 10;

  // This is when the sidebar layout collapses
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    display: none;
  }

  ${props =>
    props.isSticky
      ? `
  opacity: 0.25;
  transition: opacity 0.15s ease;
  &:hover {
    opacity: 1;
  }`
      : ``}
`

const Title = styled.h1`
  &&& {
    scroll-margin-top: 100px;

    @media screen and (max-width: ${theme.breakpoints['large']}) {
      font-size: 2rem;
      letter-spacing: -0.05rem;
    }
  }
`

const Metadata = styled.div`
  margin: 24px 0 32px 0;
  color: ${theme.colors.black500};

  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    margin: 32px 0 80px 0;
  }
`

const Bio = styled.div`
  margin: 0 0 40px 0;
  color: ${theme.colors.black500};
  display: flex;
  flex-direction: row;

  .bio-avatar {
    margin-right: 24px;

    .gatsby-image-wrapper {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  .bio-name {
    color: black;
    font-weight: 600;
  }
`
