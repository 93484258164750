import { useLocation } from '@gatsbyjs/reach-router'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Social = () => {
  const location = useLocation()
  const [url, setUrl] = useState<string>('')
  // Location is only available in the browser and we need to force this
  useEffect(() => {
    if (location.href) {
      setUrl(location.href)
    }
  }, [location.href])
  if (!url) {
    return null
  }

  const encodedUrl = encodeURIComponent(url)

  return (
    <Container>
      <a
        href={`https://twitter.com/intent/tweet/?text=${encodedUrl}\n`}
        aria-label="Share on Twitter"
        className="button big social-twitter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.54752 21.7508C16.6042 21.7508 21.5578 14.2474 21.5578 7.74052C21.5578 7.5274 21.5578 7.31524 21.5434 7.10404C22.507 6.407 23.3389 5.54392 24 4.55524C23.1014 4.95364 22.148 5.2148 21.1718 5.32996C22.1998 4.71465 22.9692 3.74674 23.3366 2.60644C22.3701 3.18005 21.3126 3.58427 20.2099 3.80164C19.4675 3.01222 18.4856 2.48948 17.4162 2.31432C16.3468 2.13917 15.2494 2.32135 14.294 2.83269C13.3385 3.34403 12.5782 4.15601 12.1307 5.14299C11.6833 6.12996 11.5735 7.23691 11.8186 8.29252C9.8609 8.19432 7.94576 7.68555 6.19745 6.79924C4.44915 5.91294 2.90676 4.6689 1.6704 3.14788C1.04073 4.23188 0.847872 5.51511 1.1311 6.7363C1.41433 7.9575 2.15234 9.02483 3.19488 9.721C2.41123 9.69804 1.64465 9.48663 0.96 9.10468V9.16708C0.960311 10.3039 1.35385 11.4057 2.07387 12.2854C2.79389 13.1652 3.79606 13.7689 4.9104 13.994C4.18548 14.1917 3.42487 14.2206 2.68704 14.0784C3.00181 15.0568 3.61443 15.9123 4.43924 16.5254C5.26405 17.1385 6.25983 17.4785 7.28736 17.498C6.26644 18.3004 5.09731 18.8938 3.84687 19.244C2.59643 19.5942 1.28921 19.6944 0 19.5389C2.25183 20.9839 4.87192 21.7504 7.54752 21.7469"
            fill="currentColor"
          />
        </svg>
        Share on Twitter
      </a>
      <a
        href={`https://facebook.com/sharer/sharer.php?u=${encodedUrl}`}
        aria-label="Share on Facebook"
        className="button big social-facebook"
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
            fill="currentColor"
          />
        </svg>
        Share on Facebook
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`}
        aria-label="Share on LinkedIn"
        className="button big social-linkedin"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.25 0.75H18.75C21.2353 0.75 23.25 2.76472 23.25 5.25V18.75C23.25 21.2353 21.2353 23.25 18.75 23.25H5.25C2.76472 23.25 0.75 21.2353 0.75 18.75V5.25C0.75 2.76472 2.76472 0.75 5.25 0.75ZM9.14754 17.25V9.41574H6.93443V17.25H9.14754ZM8.04098 8.51266C7.32836 8.51266 6.75 7.95002 6.75 7.25633C6.75 6.56264 7.32836 6 8.04098 6C8.75361 6 9.33197 6.56264 9.33197 7.25633C9.33197 7.95002 8.75434 8.51266 8.04098 8.51266ZM15.7869 17.25H18V12.4362C18 8.69499 13.8659 8.83102 12.8361 10.6728V9.41574H10.623V17.25H12.8361V13.2588C12.8361 11.0417 15.7869 10.8601 15.7869 13.2588V17.25Z"
            fill="currentColor"
          />
        </svg>
        Share on LinkedIn
      </a>
    </Container>
  )
}

export default Social

const Container = styled.div`
  margin: 24px 0 32px 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.75rem;

  .button {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`
