import React from 'react'
import { Helmet } from 'react-helmet'

export function TwitterEmbed({ tweetId }: { tweetId: string }) {
  return (
    <>
      <Helmet>
        <script async src="https://platform.twitter.com/widgets.js" />
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: `<blockquote class="twitter-tweet"><p lang="en" dir="ltr">You seem to have Twitter embeds blocked. You can reply to <a target="_blank" href="https://twitter.com/SwarmiaHQ/status/${tweetId}">this Tweet</a>.</p>&mdash; Swarmia (<a target="_blank" href="https://twitter.com/SwarmiaHQ">@SwarmiaHQ</a>)<a target="_blank" href="https://twitter.com/SwarmiaHQ/status/${tweetId}">.</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        }}
      />
    </>
  )
}
