import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from '../theme'

const Blockquote = ({
  authorTwitterHandle,
  url,
  children,
}: {
  children: ReactNode
  authorTwitterHandle?: string
  url: string
}) => {
  // There should be a <p> tag inside the blockquote
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const text = (children as any).props?.children
  return (
    <QuoteStyled>
      {children}{' '}
      {text && (
        <TwitterButton
          text={text}
          url={url}
          authorTwitterHandle={authorTwitterHandle}
        />
      )}
    </QuoteStyled>
  )
}

export default Blockquote

function TwitterButton({
  text,
  url,
  authorTwitterHandle,
}: {
  text: string
  url: string
  authorTwitterHandle?: string
}) {
  const shareText = `“${text}” – ${
    authorTwitterHandle ? `@${authorTwitterHandle}, ` : ''
  }@SwarmiaHQ\n\n${url}`
  const encodedText = encodeURIComponent(shareText)
  return (
    <TwitterLinkStyled
      href={`https://twitter.com/intent/tweet/?text=${encodedText}`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label=""
      aria-hidden="true"
      title="Tweet this"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
      </svg>
    </TwitterLinkStyled>
  )
}

const QuoteStyled = styled.blockquote`
  margin-top: 3rem;
  margin-bottom: 3rem;

  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
  color: ${theme.colors.quoteBlue};
  display: block;

  p {
    display: inline;
  }

  &::before {
    content: '“';
    width: 0.8rem;
    margin-left: -0.8rem;
    display: block;
    float: left;
  }
  /* We need to add this after p, because otherwise there is and additional space */
  p::after {
    content: '”';
    width: 0.8rem;
    display: inline-block;
  }

  /* Animate sharing icon*/
  &:hover a {
    fill: ${theme.colors.quoteBlue};
  }
`
const TwitterLinkStyled = styled.a`
  display: inline-block;
  border-bottom-width: 0 !important;
  fill: ${theme.colors.black100};

  transition: fill 0.25s linear;

  vertical-align: middle;

  svg {
    width: 28px;
    height: 28px;
  }
`
