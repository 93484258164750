import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from '../theme'

const SidebarLayout = (props: { sidebar?: ReactNode; main: ReactNode }) => {
  return (
    <Container>
      {props.sidebar && <Sidebar>{props.sidebar}</Sidebar>}
      <Main>{props.main}</Main>
    </Container>
  )
}
export default SidebarLayout

const Container = styled.div`
  display: flex;
  gap: 100px;
  width: 100%;
  @media screen and (max-width: ${theme.breakpoints['xlarge']}) {
    gap: 64px;
  }
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: center;
`

const Sidebar = styled.div`
  width: 100%;
  max-width: 352px;
  flex-grow: 0;

  @media screen and (max-width: ${theme.breakpoints['large']}) {
    max-width: 640px;
  }
`

const Main = styled.div`
  width: 100%;
  max-width: 640px;
  flex-grow: 0;
`
